import React from 'react'
import { HStack, IconButton, Spacer } from '@chakra-ui/react'
import NewPost from '../posts/NewPost'
import Posts from '../posts/Posts'
import SubSelector from '../subs/SubSelector'
import User from './User'
import { RepeatIcon } from '@chakra-ui/icons'
import { usePostRefresh } from '../../state/posts'
import DarkMode from './DarkMode'

interface SidebarProps {
  insideSub: boolean
  sub?: string
}

const Sidebar: React.FC<SidebarProps> = (props) => {
  const { forceRefresh } = usePostRefresh()

  return (
    <>
      <SubSelector insideSub={props.insideSub} />
      <HStack spacing={2} padding={2}>
        {props.sub && <NewPost sub={props.sub} aria-label="New post" />}
        <IconButton aria-label="Refresh" icon={<RepeatIcon />} onClick={() => forceRefresh()} />
        <DarkMode />
        <Spacer />
        <User />
      </HStack>
      <Posts insideSub={props.insideSub} />
    </>
  )
}

export default Sidebar
