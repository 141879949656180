export const validateUrl = (value: string): string | undefined => {
  const pattern =new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  if(!!!pattern.test(value)) {
    return 'Please enter an URL'
  }
}

export const validateLength = (minLength: number) => {
  return (value: string): string | undefined => {
    if(value.length < minLength) {
      return `Please enter at least ${minLength} characters`
    }
  }
} 