import React from 'react'
import {
  Avatar,
  Button,
  Text,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@chakra-ui/react'
import { useUser } from '../../state/user'
import { logout } from '../../utils/auth'
import Spinner from '../common/Spinner'

const User: React.FC = () => {
  const { user, isLoading } = useUser()

  if (!user) {
    return null
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Popover>
      <PopoverTrigger>
        <Button variant="link">
          <HStack spacing="2" alignItems="center">
            <Text>u/{user.name}</Text>
            <Avatar size="sm" className="avatar" src={user.icon_img} />
          </HStack>
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <Button onClick={() => logout()}>Logout</Button>
      </PopoverContent>
    </Popover>
  )
}

export default User
