import { Box } from '@chakra-ui/react'
import React from 'react'
import { Post } from '../../state/posts'

interface TwitchProps {
  post: Post
}

const Twitch: React.FC<TwitchProps> = (props) => {
  if (props.post.url.includes('https://clips.twitch.tv/')) {
    const videoId = props.post.url.replace('https://clips.twitch.tv/', '')

    return (
      <Box className="video-player-wrapper">
        <iframe
          className="video-player"
          title="Twitch embed"
          src={`https://clips.twitch.tv/embed?clip=${videoId}&parent=${window.location.hostname}`}
        />
      </Box>
    )
  }

  return null
}

export default Twitch
