import {
  Box,
  Center,
  Flex,
  useColorModeValue,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from '@chakra-ui/react'
import React from 'react'

enum RadioButtonPosition {
  First,
  Last,
  Middle,
}

const borderRadiusForPosition = (position: RadioButtonPosition, radius: number): string => {
  if (position === RadioButtonPosition.First) {
    return `${radius}px 0 0 ${radius}px`
  } else if (position === RadioButtonPosition.Last) {
    return `0 ${radius}px ${radius}px 0`
  }
  return '0'
}

interface RadioButtonProps extends UseRadioProps {
  position: RadioButtonPosition
  fillColor: string
  backgroundColor: string
  textColor: string
  selectedTextColor: string
  borderWidth: number
  labelSize?: string
  paddingX: number | string
  paddingY: number | string
}

const RadioButton: React.FC<RadioButtonProps> = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box flex="1" as="label">
      <input {...input} />
      <Center
        {...checkbox}
        cursor="pointer"
        border={`${props.borderWidth}px`}
        borderLeft={props.position === RadioButtonPosition.First ? '2px' : 0}
        borderColor={props.fillColor}
        color={props.textColor}
        backgroundColor={props.backgroundColor}
        fontSize={props.labelSize}
        _checked={{
          backgroundColor: props.fillColor,
          fontWeight: 'bold',
          color: props.selectedTextColor,
        }}
        borderRadius={borderRadiusForPosition(props.position, 5)}
        paddingX={props.paddingX}
        paddingY={props.paddingY}
        fontWeight="light"
      >
        {props.children}
      </Center>
    </Box>
  )
}

interface SegmentedControlProps {
  value?: string
  defaultValue?: string
  name?: string
  options: string[]
  onChange: (nextValue: string) => void
  fillColor?: string
  backgroundColor?: string
  textColor?: string
  selectedTextColor?: string
  borderWidth?: number
  labelSize?: string
  paddingY?: number | string
  paddingX?: number | string
}

const SegmentedControl: React.FC<SegmentedControlProps> = (props) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: props.name,
    value: props.value,
    defaultValue: props.defaultValue,
    onChange: (nextValue) => {
      props.onChange(nextValue as string)
    },
  })

  const group = getRootProps()

  const positionForIndex = (index: number): RadioButtonPosition => {
    if (index === 0) {
      return RadioButtonPosition.First
    } else if (index === props.options.length - 1) {
      return RadioButtonPosition.Last
    }
    return RadioButtonPosition.Middle
  }

  const fillColor = useColorModeValue(props.fillColor, 'gray.600')
  const selectedTextColor = useColorModeValue(props.selectedTextColor, 'gray.100')

  return (
    <Flex {...group} spacing="0" justifyContent="stretch">
      {props.options.map((value, index) => {
        // @ts-ignore
        const radio = getRadioProps({ value })

        return (
          <RadioButton
            {...radio}
            key={value}
            labelSize={props.labelSize}
            backgroundColor={props.backgroundColor!}
            fillColor={fillColor!}
            borderWidth={props.borderWidth!}
            textColor={props.textColor!}
            selectedTextColor={selectedTextColor!}
            paddingX={props.paddingX!}
            paddingY={props.paddingY!}
            position={positionForIndex(index)}
          >
            {value}
          </RadioButton>
        )
      })}
    </Flex>
  )
}

SegmentedControl.defaultProps = {
  borderWidth: 1,
  fillColor: 'gray.200',
  backgroundColor: 'clear',
  textColor: 'gray.400',
  selectedTextColor: 'gray.700',
  labelSize: 'md',
  paddingX: 2,
  paddingY: 1,
}

export default SegmentedControl
