import React from 'react'
import { AddIcon } from '@chakra-ui/icons'
import {
  IconButton,
  IconButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import PostForm from '../form/Post'
import { NewPost as NewPostType } from '../../state/submit-post'
import { usePostRefresh } from '../../state/posts'
import { Sort } from '../../utils/request'

interface NewPostProps extends IconButtonProps {
  sub: string
  onSubmit?: () => void
}

const NewPost: React.FC<NewPostProps> = (props) => {
  const { sub, onSubmit, ...rest } = props

  const { forceRefresh } = usePostRefresh()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const onSubmitForm = (values: NewPostType) => {
    forceRefresh(Sort.New)
    if (onSubmit) {
      onSubmit()
    }
    onClose()
  }

  return (
    <>
      <IconButton {...rest} onClick={onOpen} icon={<AddIcon />} />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Submit to r/{sub}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PostForm onClose={onClose} onSubmit={onSubmitForm} sub={sub} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default NewPost
