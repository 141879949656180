import React from 'react'
import { useSubmit, NewComment } from '../../state/submit-comment'
import { Button, Spacer, VStack, useToast, Textarea, HStack } from '@chakra-ui/react'
import Spinner from '../common/Spinner'
import { FieldProps, Form, Formik } from 'formik'
import { validateLength } from '../../utils/validation'
import ChakraFormikField from './ChakraFormikField'
import { Comment } from '../../state/content'

interface CommentFormProps {
  parentThingId: string
  onClose: () => void
  onCommentSaved: (newComment: Comment) => void
}

const CommentForm: React.FC<CommentFormProps> = (props) => {
  const { isLoading, submit } = useSubmit()

  const onClose = props.onClose

  const toast = useToast()

  const initialValues: NewComment = {
    thing_id: props.parentThingId,
    text: '',
  }

  if (isLoading) {
    return <Spinner />
  } else {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          try {
            const result = await submit(values)
            toast({
              title: 'Comment submitted',
              description: `Submitted to ${values.sr}`,
              status: 'success',
              duration: 4000,
              isClosable: true,
            })
            actions.setSubmitting(false)
            props.onCommentSaved(result)
          } catch (error) {
            toast({
              title: 'Error while saving comment',
              description: (error as Error).message,
              status: 'error',
              duration: 4000,
              isClosable: true,
            })
          }
        }}
      >
        {(props) => (
          <Form>
            <VStack alignItems="stretch" spacing={4}>
              <ChakraFormikField validate={validateLength(1)} fieldKey="text" label="Text">
                {({ field }: FieldProps) => {
                  return <Textarea {...field} id={field.name} value={field.value} />
                }}
              </ChakraFormikField>
              <HStack spacing={2} marginBottom={4}>
                <Spacer />
                <Button onClick={() => onClose()} click variant="ghost">
                  Cancel
                </Button>
                <Button type="submit" colorScheme="blue">
                  Submit
                </Button>
              </HStack>
            </VStack>
          </Form>
        )}
      </Formik>
    )
  }
}

export default CommentForm
