import { SmallAddIcon } from '@chakra-ui/icons'
import { Button, ButtonProps } from '@chakra-ui/react'
import React from 'react'

const ExpandButton: React.FC<ButtonProps> = (props) => {
  return (
    <Button marginY={2} {...props} variant="link" leftIcon={<SmallAddIcon />}>
      {props.children}
    </Button>
  )
}

export default ExpandButton
