import React, { RefObject, useEffect, useRef, useState } from 'react'
import {
  Button,
  chakra,
  Input,
  Flex,
  InputGroup,
  InputRightElement,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  VStack,
  Text,
  Box,
} from '@chakra-ui/react'
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom'
import SubsList from './SubsList'
import { ChevronDownIcon, SmallCloseIcon } from '@chakra-ui/icons'
import { RouteParams } from '../../pages/Sub'
import HomeIcon from '../common/icons/HomeIcon'
import { useSubs } from '../../state/subs'

interface PostsProps {
  insideSub: boolean
}

const SubSelector: React.FC<PostsProps> = (props) => {
  const { sub: urlSub } = useParams() as RouteParams
  const sub = props.insideSub ? urlSub : undefined

  const [isSubscribing, setIsSubscribing] = useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const initialFocusRef = useRef<HTMLInputElement | undefined>() as RefObject<HTMLInputElement>

  const { isSubscribed, subscribe, isLoading } = useSubs(true)

  const open = () => setIsOpen(!isOpen)
  const close = () => setIsOpen(false)

  const history = useHistory()

  useEffect(() => {
    close()
  }, [sub])

  const handleSearch = (event: React.FormEvent<HTMLFormElement> | null) => {
    event?.preventDefault()
    setSearchQuery('')
    history.push(`/r/${searchQuery}`)
  }

  const handleSubscribe = () => {
    setIsSubscribing(true)
    subscribe(sub!)
    setIsSubscribing(false)
  }

  const popoverContent = (
    <>
      <chakra.form onSubmit={handleSearch}>
        <InputGroup>
          <Input
            ref={initialFocusRef}
            placeholder="Go to sub…"
            value={searchQuery}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
            borderBottomRadius={0}
          />
          {searchQuery && (
            <InputRightElement>
              <IconButton
                onClick={() => setSearchQuery('')}
                aria-label="Clear"
                variant="link"
                icon={<SmallCloseIcon />}
              />
            </InputRightElement>
          )}
        </InputGroup>
      </chakra.form>

      {sub && !isLoading && !isSubscribed(sub) && (
        <Box padding={2}>
          <Button onClick={() => handleSubscribe()} isLoading={isSubscribing} width="100%">
            Subscribe to r/{sub}
          </Button>
        </Box>
      )}

      <SubsList maxHeight="40rem" overflowY="auto" textFilter={searchQuery} />
    </>
  )

  return (
    <Flex marginX={2} marginTop={2} alignItems="stretch">
      {props.insideSub && (
        <IconButton
          as={RouterLink}
          to="/"
          size="lg"
          aria-label="Home"
          icon={<HomeIcon />}
          borderRightRadius={0}
          backgroundColor="blue.700"
          color="white"
          _hover={{ backgroundColor: 'blue.900' }}
        />
      )}
      <Popover isOpen={isOpen} onClose={close} initialFocusRef={initialFocusRef}>
        <PopoverTrigger>
          <Button
            borderLeftRadius={!props.insideSub ? undefined : '0'}
            onClick={open}
            colorScheme="blue"
            flex="1"
            size="lg"
            rightIcon={<ChevronDownIcon />}
            _hover={{ backgroundColor: 'blue.600' }}
          >
            <VStack spacing={1}>
              <Text fontWeight="bold">{sub ? `r/${sub}` : 'Front Page'}</Text>
              {!props.insideSub && (
                <Text color="blue.800" fontSize="xs">
                  Open a sub-reddit…
                </Text>
              )}
            </VStack>
          </Button>
        </PopoverTrigger>
        <PopoverContent boxShadow="0px 10px 20px rgba(0,0,0,0.4)">
          <PopoverArrow />
          <PopoverBody padding={0}>{popoverContent}</PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  )
}

export default SubSelector
