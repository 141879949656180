import React, { RefObject, useRef } from 'react'
import { ChatIcon } from '@chakra-ui/icons'
import {
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import CommentForm from '../form/Comment'
import { Comment } from '../../state/content'

interface NewCommentProps extends ButtonProps {
  parentThingId: string
  onCommentSaved: (result: Comment) => void
}

const NewComment: React.FC<NewCommentProps> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const initialRef = useRef<HTMLTextAreaElement>() as RefObject<HTMLTextAreaElement>

  const { parentThingId, onCommentSaved, ...rest } = props

  const onCommentSavedHandler = (newComment: Comment) => {
    props.onCommentSaved(newComment)
    onClose()
  }

  return (
    <>
      <Button {...rest} size="xs" variant="ghost" onClick={onOpen} leftIcon={<ChatIcon />}>
        Reply
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialRef}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New comment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CommentForm
              onClose={onClose}
              parentThingId={props.parentThingId}
              onCommentSaved={onCommentSavedHandler}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default NewComment
