import { useCallback, useEffect } from 'react'
import { atom, selector, useRecoilState } from 'recoil'
import { useRequest } from './request'

export interface Sub {
  display_name: string
  title: string
  display_name_prefixed: string
  icon_img: string,
  user_has_favorited: boolean
}

export interface SubSidebar {
  description: string
  description_html: string
  public_description: string
  public_description_html: string
  submit_text: string
  submit_text_html: string
  title: string
  subscribers: number
}

const isLoadingState = atom<boolean>({
  key: 'isLoadingState',
  default: false
})

const subsState = atom<Sub[]>({
  key: 'subsState',
  default: [],
})

export const isSubscribedState = selector({
  key: 'isSubscribedToSub',
  get: ({get}) => {
    const subs = get(subsState)
    return (searchSub: string) => {
      return subs.find(sub => sub.display_name === searchSub)
    }
  }
})

export const useSubs = (refreshOnMount: boolean) => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const [subs, setSubs] = useRecoilState(subsState)
  const { protectedRequest } = useRequest()

  const subForName = (subName?: string): Sub|undefined => {
    if(!subName) {
      return undefined
    }
    return subs.find(sub => sub.display_name === subName)
  }

  const isSubscribed = (subName?: string): boolean => {
    return !!subForName(subName)
  }

  const fetchSubs = useCallback(async (): Promise<Sub[]> => {
    setIsLoading(true)
    setSubs([])
    const resp = await protectedRequest({
      path: 'subreddits/mine/subscriber',
      method: 'get',
      params: {
        limit: 100,
      },
    })
    const sortedSubs = resp.data.children
      .map((sub: { data: any }): Sub => sub.data)
      .sort((sub1: Sub, sub2: Sub) => (sub1.display_name.toLowerCase() > sub2.display_name.toLowerCase() ? 1 : 0))
    setSubs(sortedSubs)
    setIsLoading(false)
    return sortedSubs as Sub[]
  }, [protectedRequest, setSubs, setIsLoading])

  const subscribe = async (sub: string): Promise<any> => {
    return setSubscription(sub, 'sub')
  }

  const unsubscribe = async (sub: string): Promise<any> => {
    return setSubscription(sub, 'unsub')
  }

  const setSubscription = async (sub: string, action: string): Promise<any> => {
    setIsLoading(true)
    await protectedRequest({
      path: 'api/subscribe',
      method: 'post',
      params: {
        action,
        sr_name: sub,
      },
    })
    setIsLoading(false)
    return fetchSubs()
  }

  const isFavorite = (subName?: string): boolean => {
    const sub = subForName(subName)
    if(sub) {
      return sub.user_has_favorited
    }
    return false
  }

  const makeFavorite = async (subName: string, fav: boolean) => {
    setIsLoading(true)
    await protectedRequest({
      method: 'post',
      path: 'api/favorite',
      params: {
        make_favorite: fav,
        sr_name: subName,
      },
    })
    setIsLoading(false)
    return fetchSubs()
  }

  const getSidebar = async (subName: string): Promise<SubSidebar> => {
    const resp = await protectedRequest({
      method: 'get',
      path: `r/${subName}/about`
    })
    if(!resp.data) {
      throw new Error('Cannot get sidebar')
    }
    return resp.data
  }

  useEffect(() => {
    if(refreshOnMount) {
      fetchSubs()
    }
  }, [fetchSubs, refreshOnMount])

  return {
    isLoading,
    subs,
    fetchSubs,
    isSubscribed,
    subscribe,
    unsubscribe,
    isFavorite,
    makeFavorite,
    getSidebar
  }
}
