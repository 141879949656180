import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  initialColorMode: "light",
  useSystemColorMode: false,
  colors: {
    upvote: {
      900: '#cc3700'
    },
    downvote: {
      900: '#5a75cc'
    }
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.75rem",
    md: "0.9rem",
    lg: "1.12rem",
    xl: "1.25rem",
  }
})

export default theme