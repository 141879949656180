import React from 'react'
import { Post } from '../../state/posts'

interface VideoProps {
  post: Post
}

const Video: React.FC<VideoProps> = ({ post }: VideoProps) => {
  if (post.media?.reddit_video) {
    const embedUrl = `https://www.redditmedia.com/r/${post.subreddit}/comments/${post.id}/?ref_source=embed&ref=share&embed=true`
    return (
      <>
        <iframe
          title="Video Embed"
          id="reddit-embed"
          src={embedUrl}
          sandbox="allow-scripts allow-same-origin allow-popups"
          scrolling="no"
          width="100%"
          height="600"
        />
      </>
    )
  } else {
    return null
  }
}

export default Video
