import React from 'react'
import { Center, CenterProps, Spinner as ChakraSpinner } from '@chakra-ui/react'

const Spinner: React.FC<CenterProps> = (props) => {
  return (
    <Center mt={2} mb={2} {...props} className="spinner">
      <ChakraSpinner />
    </Center>
  )
}

export default Spinner
