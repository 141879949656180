import React from 'react'
import ReactDOM from 'react-dom'
import './layout.scss'
import * as serviceWorker from './serviceWorker'
import { RecoilRoot } from 'recoil'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import RedditCallback from './components/RedditCallback'
import Sub from './pages/Sub'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import theme from './theme'
import 'focus-visible/dist/focus-visible'

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <RecoilRoot>
        <BrowserRouter>
          <Switch>
            <Route path="/redditCallback">
              <RedditCallback />
            </Route>

            <Route path="/r/:sub/comments/:postId">
              <Sub insideSub={true} />
            </Route>

            <Route path="/r/:sub/:postId">
              <Sub insideSub={true} />
            </Route>

            <Route path="/r/:sub">
              <Sub insideSub={true} />
            </Route>

            <Route path="/:sub/:postId">
              <Sub insideSub={false} />
            </Route>

            <Route path="/">
              <Sub insideSub={false} />
            </Route>
          </Switch>
        </BrowserRouter>
      </RecoilRoot>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
