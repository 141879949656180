import { useCallback, useEffect, useState } from 'react'
import { atom, useRecoilState } from 'recoil'
import { protectedRequest as _protectedRequest } from '../utils/auth'
import { Sort } from '../utils/request'
import { Post } from './posts'
import { useRequest } from './request'
import { LikeStatus } from './vote'

export interface ReplyData {
  data: Comment
}

export interface Comment {
  id: string
  body_html: string
  body: string
  author: string
  replies?: {
    data: {
      children: ReplyData[]
    }
  }
  created_utc: number
  edited: boolean
  scope: number
  score: number
  ups: number
  downs: number
  permalink: string
  author_flair_text: string
  count: number
  parent_id: string
  children: string[]
  name: string
  link_id: string
  likes: LikeStatus
  stickied: boolean
}

const nestComments = (flatComments: Comment[]): Comment[] => {
  const allIds = flatComments.map((comment) => comment.name)
  const nestedComments = flatComments.map((parentComment) => {
    const repliesData = flatComments
      .filter((childComment) => childComment.parent_id === parentComment.name)
      .map(
        (childComment): ReplyData => {
          return { data: childComment }
        }
      )
    if (repliesData.length) {
      parentComment.replies = { data: { children: repliesData } }
    }
    return parentComment
  })
  const rootComments = nestedComments.filter((comment) => !allIds.includes(comment.parent_id))
  return rootComments
}

export const fetchChildComments = async (linkId: string, childrenIds: string[]): Promise<Comment[]> => {
  const children = childrenIds.join(',')
  const resp = await _protectedRequest({
    path: 'api/morechildren',
    method: 'post',
    params: {
      children,
      link_id: linkId,
    },
  })
  const data: ReplyData[] = resp.json.data.things
  const commentTree = nestComments(data.map((reply) => reply.data))
  return commentTree
}

const currentSortState = atom<Sort>({
  key: 'SortState',
  default: Sort.Best
})

export const useCommentsSort = () => {
  const [sort, setSort] = useRecoilState(currentSortState)
  return {
    sort,
    setSort
  }
}

interface CommentsState {
  comments: Comment[]
  sortedBy: Sort
}

export const useContent = (sub: string, postId: string) => {
  const [contentIsLoading, setContentIsLoading] = useState(false)
  const [commentsAreLoading, setCommentsAreLoading] = useState(false)

  const [postContent, setPostContent] = useState<Post | undefined>(undefined)
  const [comments, setComments] = useState<CommentsState>({comments: [], sortedBy: Sort.Best})
  const [currentSort] = useRecoilState(currentSortState)
  
  const { protectedRequest } = useRequest()

  const contentRequest = useCallback(async (sub: string, postId: string, sort = Sort.Best): Promise<{post: Post, comments: Comment[]}> => {
    const resp = await protectedRequest({
      path: `r/${sub}/comments/${postId}`,
      method: 'get',
      params: {
        limit: 30,
        sort
      },
    }, false)
    const [contentResp, commentsResp] = resp
    const contentChildren = contentResp.data.children
    const commentsChildren = commentsResp.data.children
    return {
      post: contentChildren[0].data,
      comments: commentsChildren.map((sub: { data: ReplyData }) => sub.data)
    }
  }, [protectedRequest])

  const refreshAll = useCallback(async () => {
    setContentIsLoading(true)
    setComments({comments: [], sortedBy: Sort.Best})
    setPostContent(undefined)
    const { post, comments} = await contentRequest(sub!, postId!)
    setPostContent(post)
    setComments({comments, sortedBy: Sort.Best})
    setContentIsLoading(false)
  }, [contentRequest, sub, postId])

  const appendComment = (newComment: Comment) => {
    setComments({
      ...comments,
      comments: [...comments.comments, newComment]
    })
  }

  const refreshComments = useCallback(async () => {
    setCommentsAreLoading(true)
    setComments({comments: [], sortedBy: Sort.Best})
    const { comments } = await contentRequest(sub!, postId!, currentSort)
    setComments({comments, sortedBy: currentSort})
    setCommentsAreLoading(false)
  }, [contentRequest, sub, postId, currentSort])

  useEffect(() => {
    if(sub && postId) {
      refreshAll()
    }
  }, [refreshAll, sub, postId])

  useEffect(() => {
    if(currentSort !== comments.sortedBy) {
      refreshComments()
    }
  }, [refreshComments, currentSort, comments.sortedBy])

  return {
    contentIsLoading,
    commentsAreLoading,
    currentSort,
    postContent,
    comments: comments.comments,
    refreshAll,
    fetchChildComments,
    refreshComments,
    appendComment
  }
}
