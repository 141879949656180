import { ENDPOINT, queryStringFromParams, redditRequest, Request } from './request'

export const STORAGE_KEY_TOKEN = 'reddit.access.token'
export const STORAGE_KEY_TOKEN_CREATION_TIME = 'reddit.access.token.creation.time'
export const STORAGE_KEY_REFRESH_TOKEN = 'reddit.access.refresh_token'

const CLIENT_ID = process.env.REACT_APP_REDDIT_CLIENT_ID as string
const SECRET_ID = process.env.REACT_APP_REDDIT_SECRET_ID as string

const TOKEN_LIFESPAN = 3600

const getRedirectUrl = (): string => {
  return `${window.location.origin}/redditCallback`
}

export const getLoginUrl = () => {
  const params = {
    client_id: CLIENT_ID,
    response_type: 'code',
    state: 'SIMPLE_REDDIT_CLIENT',
    redirect_uri: getRedirectUrl(),
    duration: 'permanent',
    scope: 'identity,mysubreddits,edit,read,subscribe,history,submit,save,vote',
  }
  return `${ENDPOINT}authorize?${queryStringFromParams(params)}`
}

export const setToken = (token: string, refreshToken: string | null = null) => {
  const tokenTime = Date.now()
  localStorage.setItem(STORAGE_KEY_TOKEN, token)
  localStorage.setItem(STORAGE_KEY_TOKEN_CREATION_TIME, `${tokenTime}`)
  if (refreshToken) localStorage.setItem(STORAGE_KEY_REFRESH_TOKEN, refreshToken)
}

export const getToken = (): string | null => {
  return localStorage.getItem(STORAGE_KEY_TOKEN)
}

export const getRefreshToken = (): string | null => {
  return localStorage.getItem(STORAGE_KEY_REFRESH_TOKEN)
}

export const getTokenCreationTime = (): number | null => {
  const ts = localStorage.getItem(STORAGE_KEY_TOKEN_CREATION_TIME)
  return ts ? parseInt(ts) : null
}

export const createToken = async (code: string): Promise<boolean> => {
  const resp = await redditRequest({
    endpoint: ENDPOINT,
    method: 'post',
    path: 'access_token',
    basicAuth: {
      username: CLIENT_ID,
      password: SECRET_ID,
    },
    params: {
      grant_type: 'authorization_code',
      code,
      redirect_uri: getRedirectUrl(),
    },
  })
  if (resp.access_token && resp.refresh_token) {
    setToken(resp.access_token, resp.refresh_token)
    return true
  } else {
    console.error(JSON.stringify(resp))
    return false
  }
}

const revokeToken = async (token: string, type: string) => {
  await redditRequest({
    endpoint: ENDPOINT,
    method: 'post',
    path: 'revoke_token',
    basicAuth: {
      username: CLIENT_ID,
      password: SECRET_ID,
    },
    params: {
      token,
      token_type_hint: type,
    },
  })
}

const revokeAllTokens = async () => {
  const token = getToken()
  const refreshToken = getRefreshToken()
  if (token) await revokeToken(token, 'access_token')
  if (refreshToken) await revokeToken(refreshToken, 'refresh_token')
}

export const logout = () => {
  localStorage.removeItem(STORAGE_KEY_TOKEN)
  localStorage.removeItem(STORAGE_KEY_TOKEN_CREATION_TIME)
  localStorage.removeItem(STORAGE_KEY_REFRESH_TOKEN)
  revokeAllTokens()
  window.location.reload()
}

const tokenNeedsRefresh = (): boolean => {
  const tokenTime = getTokenCreationTime()
  const now = Date.now()
  const tokenAge = (now - (tokenTime as number)) / 1000
  return tokenAge > TOKEN_LIFESPAN
}

export const protectedRequest = async (request: Request): Promise<any> => {
  if (tokenNeedsRefresh()) {
    console.log('refreshing token')
    const resp = await redditRequest({
      endpoint: ENDPOINT,
      method: 'post',
      path: 'access_token',
      basicAuth: {
        username: CLIENT_ID,
        password: SECRET_ID,
      },
      params: {
        grant_type: 'refresh_token',
        refresh_token: getRefreshToken() as string,
        redirect_uri: getRedirectUrl(),
      },
    })
    if (resp.access_token) {
      setToken(resp.access_token)
    } else {
      console.error(resp)
      throw new Error('cannot refresh token')
    }
  }

  if (!getToken()) {
    throw new Error('cannot request without token')
  }

  request.token = getToken() as string
  return redditRequest(request)
}

export const isLoggedIn = (): boolean => {
  return !!getToken()
}
