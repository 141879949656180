import { useRequest } from './request'
import { RequestParams } from '../utils/request'
import { Comment } from './content'

export type NewCommentKind = 'link' | 'self'

export interface NewComment extends RequestParams {
  thing_id: string
  text: string
}

export const useSubmit = () => {
  const { isLoading, protectedRequest } = useRequest()

  const submit = async (newComment: NewComment): Promise<Comment> => {
    const resp = await protectedRequest({
      path: 'api/comment',
      method: 'post',
      params: newComment
    })
    if(resp.json.errors.length) {
      const errorMessage = resp.json.errors.map((err: string[]) => err[1]).join(', ')
      throw new Error(errorMessage)
    }
    if(resp.json.data.things && resp.json.data.things.length === 1) {
      const thing = resp.json.data.things[0]
      return thing.data as Comment
    } else {
      throw new Error('Unexpected response')
    }
  }

  return {
    isLoading,
    submit
  }
}