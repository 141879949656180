import { useState } from 'react'
import { protectedRequest } from '../utils/auth'

export type VoteType = number | null
export type LikeStatus = boolean | null

export const vote = async (fullname: string, dir: VoteType) => {
  return await protectedRequest({
    path: 'api/vote',
    method: 'post',
    params: {
      dir,
      id: fullname,
    },
  })
}

export const useVote = () => {
  const [isUpvoting, setIsUpvoting] = useState(false)
  const [isDownvoting, setIsDownvoting] = useState(false)

  const upvote = async (fullname: string) => {
    setIsUpvoting(true)
    await vote(fullname, 1)
    setIsUpvoting(false)
  }

  const downvote = async (fullname: string) => {
    setIsDownvoting(true)
    await vote(fullname, -1)
    setIsDownvoting(false)
  }

  const unvote = async (fullname: string) => {
    setIsUpvoting(true)
    setIsDownvoting(true)
    await vote(fullname, 0)
    setIsDownvoting(false)
    setIsUpvoting(false)

  }

  return {
    upvote,
    isUpvoting,
    downvote,
    isDownvoting,
    unvote
  }
}
