import { useRequest } from './request'
import { RequestParams } from '../utils/request'

export type NewPostKind = 'link' | 'self'

export interface NewPost extends RequestParams {
  sr: string
  kind: NewPostKind
  text?: string
  title?: string
  url?: string
}

export const useSubmit = () => {
  const { isLoading, protectedRequest } = useRequest()

  const submit = async (newPost: NewPost) => {
    const resp = await protectedRequest({
      path: 'api/submit',
      method: 'post',
      params: newPost
    })
    if(resp.json.errors.length) {
      const errorMessage = resp.json.errors.map((err: string[]) => err[1]).join(', ')
      throw new Error(errorMessage)
    }
    return resp.json.data
  }

  return {
    isLoading,
    submit
  }
}