import { useCallback, useState } from 'react'
import { protectedRequest } from '../utils/auth'
import { redditRequest, Request } from '../utils/request'

export const useRequest = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<any>(null)

  const request = async (req: Request): Promise<any> => {
    try {
      setIsLoading(true)
      return await redditRequest(req)
    } catch (error) {
      setError(error)
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const _protectedRequest = useCallback(async (req: Request, loadingIndication = true): Promise<any> => {
    try {
      if(loadingIndication) {
        setIsLoading(true)
      }
      return await protectedRequest(req)
    } catch (error) {
      setError(error)
      console.error(error)
    } finally {
      if(loadingIndication) {
        setIsLoading(false)
      }
    }
  }, [])

  return {
    isLoading,
    error,
    request,
    protectedRequest: _protectedRequest,
  }
}
