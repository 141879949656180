import { Button } from '@chakra-ui/button'
import { useDisclosure } from '@chakra-ui/hooks'
import { Heading } from '@chakra-ui/layout'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import React, { useState } from 'react'
import { SubSidebar, useSubs } from '../../state/subs'
import UserContent from '../content/UserContent'

interface SidebarOverlayProps {
  sub: string
}

const SidebarOverlay: React.FC<SidebarOverlayProps> = ({ sub }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { getSidebar } = useSubs(false)
  const [sidebar, setSidebar] = useState<SubSidebar | undefined>(undefined)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const openSidebar = async (event: React.MouseEvent) => {
    setIsLoading(true)
    event.stopPropagation()
    event.preventDefault()
    const sidebar = await getSidebar(sub)
    setSidebar(sidebar)
    onOpen()
    setIsLoading(false)
  }

  return (
    <>
      <Button isLoading={isLoading} onClick={openSidebar}>
        About sub-reddit
      </Button>
      {sidebar && (
        <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
          <ModalOverlay />
          <ModalContent maxHeight="90%">
            <ModalHeader>About sub-reddit</ModalHeader>
            <ModalCloseButton />
            <ModalBody overflowY="auto">
              <Heading as="h2" marginBottom={4}>
                {sidebar.title}
              </Heading>
              <Heading as="h3" marginBottom={3}>
                {sidebar.subscribers} subscribers
              </Heading>
              <UserContent content={sidebar.public_description_html} marginBottom={4} />
              <UserContent content={sidebar.description_html} marginBottom={4} />
              <UserContent content={sidebar.submit_text_html} marginBottom={4} />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

export default SidebarOverlay
