import React from 'react'
import { useParams } from 'react-router-dom'
import { Sub, useSubs } from '../../state/subs'
import Spinner from '../common/Spinner'
import { RouteParams } from '../../pages/Sub'
import { Text, Box, VStack, BoxProps, useColorModeValue } from '@chakra-ui/react'
import SubItem from './SubItem'

interface SubsSectionProps {
  textFilter?: string
  subs: Sub[]
  title: string
}

const SubSection: React.FC<SubsSectionProps> = (props) => {
  const routeParams = useParams() as RouteParams

  const groupBackground = useColorModeValue('gray.50', 'gray.800')

  if (props.subs.length === 0) {
    return null
  }

  const isCurrentSub = (sub: string): boolean => {
    return routeParams.sub === sub
  }

  const sortedSubs = props.subs.sort((s1, s2) => {
    return s1.display_name.toLowerCase() < s2.display_name.toLowerCase() ? -1 : 1
  })

  return (
    <Box marginTop="2">
      <Text
        color="gray.400"
        fontSize="lg"
        textTransform="uppercase"
        fontWeight="light"
        marginTop={4}
        marginBottom={2}
        marginX={4}
      >
        {props.title}
      </Text>
      <VStack alignItems="stretch" spacing={0} backgroundColor={groupBackground}>
        {sortedSubs.map((sub) => {
          return (
            <SubItem key={sub.display_name} sub={sub} isCurrent={isCurrentSub(sub.display_name)} />
          )
        })}
      </VStack>
    </Box>
  )
}

interface SubsListProps extends BoxProps {
  textFilter?: string
}

const SubsList: React.FC<SubsListProps> = (props) => {
  const { textFilter, ...rest } = props
  const { subs, isLoading } = useSubs(false)

  let visibleSubs = subs
  if (textFilter) {
    visibleSubs = subs?.filter((sub) => {
      return sub.display_name.toLowerCase().includes(textFilter.toLowerCase())
    })
  }

  const favouriteSubs = visibleSubs.filter((sub) => sub.user_has_favorited)
  const otherSubs = visibleSubs.filter((sub) => !sub.user_has_favorited)

  if (isLoading) {
    return <Spinner marginY={4} />
  } else if (visibleSubs.length > 0) {
    return (
      <Box {...rest}>
        <SubSection textFilter={textFilter} title="Favourites" subs={favouriteSubs} />
        <SubSection textFilter={textFilter} title="Other" subs={otherSubs} />
      </Box>
    )
  } else {
    return (
      <Box padding={4}>No matching sub in subscription. Press enter to visit r/{textFilter}</Box>
    )
  }
}

export default SubsList
