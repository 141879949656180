import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'
import he from 'he'

interface UserContentProps extends BoxProps {
  content: string
}

const UserContent: React.FC<UserContentProps> = (props) => {
  const decodedHtml = props.content ? he.decode(props.content) : ''

  const { content, ...rest } = props

  const handleLinkClick = (event: React.MouseEvent) => {
    const target = event.target as any
    if (target.nodeName === 'A') {
      if (window.location.hostname !== target.hostname) {
        event.preventDefault()
        window.open(target.href)
      }
    }
  }

  if (decodedHtml) {
    return (
      <Box
        {...rest}
        onClick={handleLinkClick}
        className={`${props.className} user-content`}
        dangerouslySetInnerHTML={{ __html: decodedHtml }}
      />
    )
  } else {
    return null
  }
}

export default UserContent
