import React, { useEffect } from 'react'
import { Post, usePosts } from '../../state/posts'
import Spinner from '../common/Spinner'
import PostLink from './PostLink'
import { NavLink, useParams } from 'react-router-dom'
import { RouteParams } from '../../pages/Sub'
// import { Helmet } from 'react-helmet'
import { Text, Box, Button, Center, Link, Flex, useColorModeValue } from '@chakra-ui/react'
import SegmentedControl from '../common/SegmentedControl'
import { AddIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Sort } from '../../utils/request'

interface PostsProps {
  insideSub: boolean
}

const Posts: React.FC<PostsProps> = (props) => {
  const { postId, sub: urlSub } = useParams() as RouteParams
  const sub = props.insideSub ? urlSub : undefined

  const { posts, fetchMore, isLoading, showError, sort, setSort, hasMorePosts } = usePosts(sub)

  const pageTitle = sub ? `r/${sub} • reddit reader` : `front page • reddit reader`

  useEffect(() => {
    document.title = pageTitle
  }, [pageTitle])

  const subLink = (post: Post) => {
    if (!sub && postId === post.id) {
      return (
        <Link as={NavLink} to={`/r/${post.subreddit}/${post.id}`}>
          <Flex padding="1" backgroundColor="blue.900" color="white" fontWeight="bold">
            <ChevronRightIcon />
            <Text fontSize="xs">see in r/{post.subreddit}</Text>
          </Flex>
        </Link>
      )
    }
    return null
  }

  const borderColor = useColorModeValue('gray.100', 'gray.700')

  if (showError) {
    return <p>this sub does not exist</p>
  } else {
    return (
      <>
        <Box paddingX={2} marginBottom={4}>
          <SegmentedControl
            value={sort}
            options={[Sort.Hot, Sort.New, Sort.Top]}
            onChange={(nextValue: string) => {
              setSort(nextValue as Sort)
            }}
          />
        </Box>

        {posts.map((post) => {
          return (
            <Box borderBottom="2px" borderColor={borderColor} key={post.id}>
              <PostLink post={post} isActive={post.id === postId} inCurrentSub={!!sub} />
              {subLink(post)}
            </Box>
          )
        })}

        {!isLoading && hasMorePosts && (
          <Center marginY="6" className="load-more">
            <Button onClick={() => fetchMore()} leftIcon={<AddIcon />}>
              show more
            </Button>
          </Center>
        )}

        {isLoading && (
          <Box marginY="8">
            <Spinner />
          </Box>
        )}
      </>
    )
  }
}

export default Posts
