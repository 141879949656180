import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { fetchPost, Post } from '../../state/posts'
import * as timeago from 'timeago.js'
import { PostVote } from '../common/Vote'
import Spinner from '../common/Spinner'
import {
  Box,
  Center,
  Flex,
  HStack,
  IconButton,
  Image,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import { ArrowUpDownIcon, ChatIcon, LinkIcon } from '@chakra-ui/icons'
import styled from '@emotion/styled'

interface PostLinkProps {
  post: Post
  inCurrentSub: boolean
  isActive: boolean
}

const PostLinkElement = styled(NavLink)`
  &:visited {
    color: #999;
  }
`

const PostLink: React.FC<PostLinkProps> = ({ post, inCurrentSub, isActive }: PostLinkProps) => {
  const [currentPost, setCurrentPost] = useState<Post>(post)
  const [isLoading, setIsLoading] = useState(false)

  const url = inCurrentSub
    ? `/r/${currentPost.subreddit}/${currentPost.id}`
    : `/${currentPost.subreddit}/${currentPost.id}`

  const refreshPost = async () => {
    setIsLoading(true)
    const resp = await fetchPost(currentPost.subreddit, currentPost.id)
    setCurrentPost(resp)
    setIsLoading(false)
  }

  const isSelfPost = !post.post_hint || post.post_hint === 'self'

  const handleExternalLink = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    window.open(post.url)
  }

  const selectedColor = useColorModeValue('blue.800', 'gray.700')
  const highlightColor = useColorModeValue('white', 'gray.900')

  return (
    <PostLinkElement to={url}>
      <Box position="relative">
        <Flex
          background={isActive ? selectedColor : undefined}
          _hover={{ backgroundColor: isActive ? selectedColor : highlightColor }}
          color={isActive ? 'white' : undefined}
          padding="2"
        >
          <Box marginRight="2">
            <PostVote
              direction="column"
              thingId={currentPost.name}
              likes={currentPost.likes}
              onVote={refreshPost}
            />
          </Box>
          {!!currentPost.thumbnail && currentPost.thumbnail.includes('https') && (
            <Image
              boxSize="70px"
              objectFit="cover"
              borderRadius="5px"
              src={currentPost.thumbnail}
              alt={currentPost.title}
              marginRight="2"
            />
          )}

          <VStack alignItems="flex-start" flex="1" marginRight="2" spacing="1">
            <Text
              color={isActive ? 'white' : undefined}
              fontSize="md"
              fontWeight="bold"
              overflowWrap="break-word"
              wordBreak="break-word"
            >
              {currentPost.title}
            </Text>
            <HStack>
              {!inCurrentSub && <Text fontSize="xs">r/{currentPost.subreddit}</Text>}
              <Text fontSize="xs">{currentPost.domain}</Text>
            </HStack>
            <HStack color="gray.500">
              <Text fontSize="xs">{currentPost.author}</Text>
              <Text fontSize="xs">{timeago.format(new Date(currentPost.created_utc * 1000))}</Text>
            </HStack>
          </VStack>

          <VStack opacity="0.7" spacing="2" alignItems="flex-end">
            <HStack spacing="1">
              <Text fontSize="xs">{currentPost.num_comments}</Text>
              <ChatIcon boxSize="0.7rem" className="icon" />
            </HStack>
            <HStack spacing="1">
              <Text fontSize="xs">{currentPost.score}</Text>
              <ArrowUpDownIcon boxSize="0.7rem" className="icon" />
            </HStack>
            <Spacer flex="1" />
            {!isSelfPost && (
              <IconButton
                variant="ghost"
                onClick={handleExternalLink}
                aria-label="Visit external link"
                size="sm"
                _hover={{ backgroundColor: highlightColor, color: 'white' }}
                icon={<LinkIcon />}
              />
            )}
          </VStack>
        </Flex>
        {isLoading && (
          <Center
            position="absolute"
            inset="0"
            backgroundColor="rgba(255,255,255,0.7)"
            className="loading-overlay"
          >
            <Spinner />
          </Center>
        )}
      </Box>
    </PostLinkElement>
  )
}

export default PostLink
