import React from 'react'
import { MoonIcon, SunIcon } from '@chakra-ui/icons'
import { IconButton, useColorMode } from '@chakra-ui/react'

interface DarkModeProps {}

const DarkMode: React.FC<DarkModeProps> = (props) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const icon = colorMode === 'dark' ? <SunIcon /> : <MoonIcon />
  return <IconButton onClick={toggleColorMode} aria-label="Toggle dark mode" icon={icon} />
}

export default DarkMode
