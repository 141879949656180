import React from 'react'
import { useParams } from 'react-router-dom'
import Content from '../components/content/Content'
import { isLoggedIn } from '../utils/auth'
import Login from '../components/Login'
import { Flex, Box, Center, useColorModeValue } from '@chakra-ui/react'
import Sidebar from '../components/sidebar/Sidebar'

export const SCROLLABLE_CONTAINER_ID = 'main-scrollable-container'

export interface RouteParams {
  sub?: string
  postId?: string
}

interface SubProps {
  insideSub: boolean
}

const Sub: React.FC<SubProps> = (props) => {
  const params = useParams() as RouteParams

  const sidebarBackground = useColorModeValue('gray.50', 'gray.800')

  const contentOpened = params.postId && params.sub

  const sideBar = (
    <Box
      className="pane-sidebar scrollable"
      height="100%"
      width={{ base: '100%', lg: '440px' }}
      display={{ base: contentOpened ? 'none' : 'block', lg: 'block' }}
      overflowY="scroll"
      backgroundColor={sidebarBackground}
    >
      <Sidebar insideSub={props.insideSub} sub={params.sub} />
    </Box>
  )

  const content = (
    <Box
      className="pane-main scrollable"
      id={SCROLLABLE_CONTAINER_ID}
      flex="1"
      height="100%"
      overflowY="auto"
      display={{ base: contentOpened ? 'block' : 'none', lg: 'block' }}
    >
      {contentOpened ? (
        <Content sub={params.sub!} postId={params.postId!} />
      ) : (
        <Center flex="1" height="100%">
          <span>Open a post on left pane</span>
        </Center>
      )}
    </Box>
  )

  if (isLoggedIn()) {
    return (
      <Flex height="100%">
        {sideBar}
        {content}
      </Flex>
    )
  } else {
    return <Login />
  }
}

export default Sub
