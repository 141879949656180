import { useCallback, useEffect, useState } from 'react'
import { atom, useRecoilState } from 'recoil'
import { protectedRequest } from '../utils/auth'
import { Sort } from '../utils/request'
import { useRequest } from './request'
import { LikeStatus } from './vote'

export interface ImageLink {
  y?: number
  x?: number
  u: string
}

export interface ImageMetadata {
  id: string
  p: ImageLink[]
  s: ImageLink,
  m: string,
  status?: string
}

export interface Post {
  id: string
  name: string
  permalink: string
  title: string
  selftext: string
  selftext_html: string
  author: string
  url: string
  thumbnail: string
  subreddit: string
  num_comments: number
  score: number
  created_utc: number
  domain: string
  likes: LikeStatus
  post_hint: string
  media_metadata?: {
    [key: string]: ImageMetadata
  }
  media?: {
    reddit_video?: {
      fallback_url: string
      width: number
      height: number
      duration: number
      hls_url: string
    }
  }
}

// TODO move to separate file
export const fetchPost = async (sub: string, postId: string) => {
  const resp = await protectedRequest({
    path: `r/${sub}/comments/${postId}`,
    method: 'get',
  })
  return resp[0].data.children[0].data
}

const getPath = (sub: string | undefined, sort: Sort): string => {
  return !!sub ? `r/${sub}/${sort}` : ''
}

interface CurrentSubState {
  sub?: string,
  sort: Sort,
  forceRefresh?: string
}

const currentSubState = atom<CurrentSubState>({
  key: 'CurrentSubState',
  default: {
    sort: Sort.Hot
  }
})

interface CurrentPostsState {
  posts: Post[],
  after?: string
}

export const usePostRefresh = () => {
  const [currentSub, setCurrentSub] = useRecoilState(currentSubState)

  const forceRefresh = (sort?: Sort) => {
    setCurrentSub({
      ...currentSub,
      sort: sort ?? currentSub.sort,
      forceRefresh: (new Date()).toDateString()
    })
  }

  return {
    forceRefresh
  }
}

export const usePosts = (sub: string | undefined) => {
  const { isLoading, protectedRequest } = useRequest()

  const [currentPosts, setCurrentPosts] = useState<CurrentPostsState>({posts: []})
  const [currentSub, setCurrentSub] = useRecoilState(currentSubState)

  const [showError, setShowError] = useState<boolean>(false)

  const extractPosts = (resp: any): Post[] => {
    return resp.data.children.map((sub: { data: any }) => sub.data)
  }

  const fetchPosts = useCallback(async () => {
    setCurrentPosts({ posts: []})
    setShowError(false)
    try {
      const resp = await protectedRequest({
        path: getPath(currentSub.sub, currentSub.sort),
        method: 'get',
      })
      const result = extractPosts(resp)
      setCurrentPosts({
        posts: result,
        after: resp.data.after
      })
    } catch (error) {
      setShowError(true)
      console.error(['posts', error])
      return []
    }
  }, [currentSub.sort, currentSub.sub, setCurrentPosts, protectedRequest])

  const fetchMore = async () => {
    if (!currentPosts.posts || !currentPosts.posts?.length) throw new Error('no current posts')
    if (!currentPosts.after) throw new Error('no after value')
    try {
      const resp = await protectedRequest({
        path: getPath(currentSub.sub, currentSub.sort),
        params: { after: currentPosts.after },
        method: 'get',
      })
      const existingPostIds = currentPosts.posts.map(post => post.id)
      // to avoid duplicates
      const newPosts = extractPosts(resp).filter(post  => !existingPostIds.includes(post.id))
      setCurrentPosts({
        posts: [...currentPosts.posts, ...newPosts],
        after: resp.data.after
      })
    } catch (error) {
      console.error(['posts', error])
    }
  }

  const setSort = (sort: Sort) => {
    setCurrentSub({...currentSub, sort})
  }
  
  useEffect(() => {
    setCurrentSub({ sort: currentSub.sort, sub })
  }, [sub, currentSub.sub, currentSub.sort, setCurrentSub])
  
  useEffect(() => {
    // only fetch new posts if sub in state is the current sub passed from url params
    // (to avoid double-fetching when not on front page)
    if(currentSub.sub === sub) {
      fetchPosts()
    }
  }, [fetchPosts, currentSub.sub, currentSub.sort, currentSub.forceRefresh, sub])

  return {
    setSort,
    sort: currentSub.sort,
    posts: currentPosts.posts,
    showError,
    fetchPosts,
    fetchMore,
    isLoading,
    hasMorePosts: !!currentPosts.after
  }
}
