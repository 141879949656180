import { useCallback, useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'
import { useRequest } from './request'

interface User {
  name: string
  comment_karam: number
  created: string
  inbox_count: string
  modhash: string
  icon_img: string
}

const userState = atom<User | undefined>({
  key: 'userState',
  default: undefined,
})

export const useUser = () => {
  const [user, setUser] = useRecoilState(userState)
  const { isLoading, protectedRequest } = useRequest()

  const fetchUser = useCallback(async () => {
    const resp = await protectedRequest({
      path: 'api/v1/me',
      method: 'get',
    })
    setUser(resp)
  }, [protectedRequest, setUser])

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  return {
    user,
    fetchUser,
    isLoading,
  }
}
