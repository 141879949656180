import { Box, Button, Center } from '@chakra-ui/react'
import React from 'react'
import { getLoginUrl } from '../utils/auth'

const Login: React.FC = () => {
  const openRedditAuth = () => {
    window.location.href = getLoginUrl()
  }

  return (
    <Center className="login" height="100%">
      <Box title="Login to your reddit account">
        <Button onClick={() => openRedditAuth()}>Login with reddit</Button>
      </Box>
    </Center>
  )
}

export default Login
