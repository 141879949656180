import React from 'react'
import ReactPlayer from 'react-player'
import { Box } from '@chakra-ui/react'
import { Post } from '../../state/posts'

interface YoutubeProps {
  post: Post
}

const Youtube: React.FC<YoutubeProps> = ({ post }) => {
  if (post.url.includes('youtube.com/watch') || post.url.includes('//youtu.be/')) {
    return (
      <Box className="video-player-wrapper">
        <ReactPlayer
          className="video-player react-player"
          width="100%"
          height="100%"
          controls={true}
          url={post!.url}
        />
      </Box>
    )
  } else {
    return null
  }
}

export default Youtube
