import React, { useRef, useState } from 'react'
import { Comment, ReplyData } from '../../state/content'
import MoreComments from './MoreComments'
import { Box, Flex, Center, useColorModeValue } from '@chakra-ui/react'
import styled from '@emotion/styled'
import UserContent from '../content/UserContent'
import ExpandButton from './ExpandButton'
import Header from './Header'
import { SCROLLABLE_CONTAINER_ID } from '../../pages/Sub'
import { Collapse } from '@chakra-ui/react'

interface CommentProps {
  comment: Comment
  postId: string
  postAuthor: string
}

const CommentCollapseBar = styled(Center)`
  position: relative;
  right: 10px;
  width: 20px;
  cursor: pointer;
  &:hover {
    .visible-bar {
      background-color: #444;
    }
  }
`

const CommentHoverBox = styled(Box)`
  .show-on-comment-hover {
    opacity: 0;
  }
  &:hover {
    .show-on-comment-hover {
      opacity: 1;
    }
  }
`

const CommentComp: React.FC<CommentProps> = (props) => {
  const [showCommentTree, setShowCommentTree] = useState(true)
  const [addedReplies, setAddedReplies] = useState<ReplyData[]>([])
  const commentElement = useRef<HTMLDivElement>(null)

  const replies = props.comment.replies ? props.comment.replies?.data.children : []
  const allReplies = [...replies, ...addedReplies]

  const toggleCommentTree = () => {
    const container = document.getElementById(SCROLLABLE_CONTAINER_ID)
    if (
      showCommentTree &&
      commentElement.current &&
      container &&
      commentElement.current.offsetTop < container.scrollTop
    ) {
      container.scrollTop = commentElement.current.offsetTop - 70
    }
    setShowCommentTree(!showCommentTree)
  }

  const handleReplySave = (newComment: Comment) => {
    setAddedReplies([...addedReplies, { data: newComment }])
  }

  const collapseBarColor = useColorModeValue('gray.100', 'gray.700')

  if (showCommentTree && props.comment.children) {
    return (
      <MoreComments
        postAuthor={props.postAuthor}
        commentPlaceholder={props.comment}
        postId={props.postId}
      />
    )
  }

  return (
    <>
      <Collapse in={showCommentTree} animateOpacity>
        <Flex marginY="4" className="comment-tree" ref={commentElement}>
          <CommentCollapseBar onClick={() => toggleCommentTree()}>
            <Box
              width="2px"
              height="100%"
              backgroundColor={collapseBarColor}
              className="visible-bar"
            />
          </CommentCollapseBar>
          <Box flex="1" className="comment">
            <CommentHoverBox>
              <Header
                marginBottom={1}
                comment={props.comment}
                postAuthor={props.postAuthor}
                onReplySave={handleReplySave}
              />
              <UserContent content={props.comment.body_html} marginBottom="2" fontSize="md" />
            </CommentHoverBox>
            {allReplies.length > 0 && (
              <>
                {allReplies.map((reply) => {
                  return (
                    <CommentComp
                      postAuthor={props.postAuthor}
                      postId={props.postId}
                      comment={reply.data}
                      key={reply.data.id}
                    />
                  )
                })}
              </>
            )}
          </Box>
        </Flex>
      </Collapse>

      {!showCommentTree && (
        <div className="show-replies">
          <ExpandButton onClick={() => toggleCommentTree()}>show comment</ExpandButton>
        </div>
      )}
    </>
  )
}

export default CommentComp
