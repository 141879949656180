export const ENDPOINT = 'https://www.reddit.com/api/v1/'
export const OAUTH_ENDPOINT = 'https://oauth.reddit.com/'

export type RequestParams = { [key: string]: string | number | boolean | null | undefined }

export enum Sort {
  Best = 'confidence',
  Hot = 'hot',
  New = 'new',
  Top = 'top',
  Controversial = 'controversial'
}

export interface Request {
  endpoint?: string
  token?: string
  method: string
  path: string
  params?: RequestParams
  basicAuth?: {
    username: string
    password: string
  }
}

export const queryStringFromParams = (params: RequestParams): string => {
  return Object.keys(params)
    .map((key) => {
      const value = params[key]
      return `${key}=${value}`
    })
    .join('&')
}

export const paramsFromQueryString = (queryString: string): RequestParams => {
  return queryString.split('&').reduce((result: RequestParams, paramString) => {
    const components = paramString.split('=')
    result[components[0]] = components[1]
    return result
  }, {})
}

export const redditRequest = async (request: Request): Promise<any> => {
  const endpoint = request.endpoint || OAUTH_ENDPOINT

  const headers = new Headers()
  headers.append('Content-Type', 'application/x-www-form-urlencoded')
  if (request.token) {
    headers.append('Authorization', 'bearer ' + request.token)
  } else if (request.basicAuth) {
    headers.set('Authorization', 'Basic ' + btoa(request.basicAuth.username + ':' + request.basicAuth.password))
  }

  const finalParams = { ...request.params, api_type: 'json' }

  const paramsString = finalParams ? queryStringFromParams(finalParams) : null
  let url = `${endpoint}${request.path}`
  let body = null
  if (request.method.toLowerCase() === 'get') {
    url = paramsString ? `${url}/?${paramsString}` : url
  } else {
    body = paramsString
  }

  const resp = await fetch(url, {
    method: request.method.toLowerCase(),
    headers,
    body,
  })

  if (resp.status === 401) {
    throw new Error('unauthorized')
  }

  return resp.json()
}
