import React from 'react'
import { ImageMetadata, Post } from '../../state/posts'
import Gallery, { GalleryImage } from '../common/Gallery'

interface ImagesProps {
  post: Post
}

const getImageUrls = (medias: ImageMetadata[]): string[] => {
  return medias
    .filter((media) => media.status !== 'failed' && !!media.m)
    .map((media) => {
      const extension = media.m.split('/')[1]
      return `https://i.redd.it/${media.id}.${extension}`
    })
}

const Images: React.FC<ImagesProps> = ({ post }) => {
  if (post.media_metadata) {
    const imageUrls = getImageUrls(Object.values(post.media_metadata))
    const imagesForGallery = imageUrls.map((url) => {
      return {
        imageUrl: url,
      } as GalleryImage
    })
    return <Gallery images={imagesForGallery} />
  } else if (post.url.startsWith('https://i.redd.it/')) {
    return <Gallery images={[{ imageUrl: post.url }]} />
  } else {
    return null
  }
}

export default Images
