import React from 'react'
import {
  BoxProps,
  Button,
  Flex,
  HStack,
  Spacer,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react'
import { LinkIcon } from '@chakra-ui/icons'
import { Post } from '../../state/posts'
import SegmentedControl from '../common/SegmentedControl'
import { Sort } from '../../utils/request'
import { Comment, useCommentsSort } from '../../state/content'
import NewComment from '../comments/NewComment'

interface ToolbarProps extends BoxProps {
  post: Post
  onNewComment: (comment: Comment) => void
}

const Toolbar: React.FC<ToolbarProps> = (props) => {
  const { post, onNewComment, ...rest } = props

  const { sort, setSort } = useCommentsSort()

  const backgroundColor = useColorModeValue('gray.200', 'gray.700')

  const redditUrl = `https://old.reddit.com/${post.permalink}`

  return (
    <Flex
      direction={{ base: 'column', lg: 'row' }}
      position="sticky"
      top={2}
      bottom={2}
      backgroundColor={backgroundColor}
      paddingY={2}
      paddingX={4}
      borderRadius={6}
      zIndex="2"
      alignItems={{ base: 'flex-start', lg: 'center' }}
      {...rest}
    >
      <HStack marginRight={4}>
        <Text fontSize="sm">{post.num_comments} comments</Text>
        <NewComment
          marginRight={4}
          parentThingId={props.post.name}
          onCommentSaved={(comment) => props.onNewComment(comment)}
        />
      </HStack>
      <SegmentedControl
        value={sort}
        labelSize="xs"
        onChange={(value: string) => setSort(value as Sort)}
        options={[Sort.Best, Sort.New, Sort.Top, Sort.Controversial]}
        fillColor="gray.300"
      />
      <Spacer />
      <Tooltip label={redditUrl} overlayClassName="url-tooltip">
        <Button
          as="a"
          href={redditUrl}
          variant="link"
          onClick={(event: React.MouseEvent) => {
            event.preventDefault()
            window.open(redditUrl)
          }}
          leftIcon={<LinkIcon />}
          mr={4}
        >
          View on reddit
        </Button>
      </Tooltip>
      <Tooltip label={post.url} overlayClassName="url-tooltip">
        <Button
          as="a"
          href={post.url}
          variant="link"
          onClick={(event: React.MouseEvent) => {
            event.preventDefault()
            window.open(post.url)
          }}
          leftIcon={<LinkIcon />}
        >
          Open link
        </Button>
      </Tooltip>
    </Flex>
  )
}

export default Toolbar
