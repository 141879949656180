import React, { ReactNode } from 'react'
import { BoxProps, HStack, Text, Tooltip } from '@chakra-ui/react'
import { Comment } from '../../state/content'
import * as timeago from 'timeago.js'
import { CommentVote } from '../common/Vote'
import { EditIcon, InfoIcon } from '@chakra-ui/icons'
import NewComment from './NewComment'

interface CommentHeader extends BoxProps {
  comment: Comment
  onReplySave: (comment: Comment) => void
  postAuthor: string
}

const Header: React.FC<CommentHeader> = (props) => {
  const { comment, postAuthor, onReplySave, ...rest } = props

  const authorIsOp = postAuthor === props.comment.author

  let authorColor: string | undefined = undefined
  let authorIcon: ReactNode | undefined = undefined

  if (authorIsOp) {
    authorColor = 'orange.500'
    authorIcon = <EditIcon color={authorColor} />
  } else if (props.comment.stickied) {
    authorColor = 'green.500'
    authorIcon = <InfoIcon color={authorColor} />
  }

  const profileUrl = `https://www.reddit.com/user/${props.comment.author}/`

  return (
    <HStack {...rest} wrap="wrap" className="meta">
      <Tooltip label={props.comment.author_flair_text} placement="top">
        <HStack>
          {authorIcon}
          <Text fontWeight="bold" fontSize="sm" color={authorColor}>
            <a href={profileUrl} target="_blank" rel="noopener noreferrer">
              {props.comment.author}
            </a>
          </Text>
        </HStack>
      </Tooltip>
      <Text color="gray.400" fontSize="sm">
        {timeago.format(new Date(props.comment.created_utc * 1000))}
      </Text>
      <Text color="gray.400" fontSize="sm">
        {props.comment.score} points
      </Text>
      <CommentVote
        className="show-on-comment-hover"
        direction="row"
        thingId={props.comment.name}
        likes={props.comment.likes}
      />
      <NewComment
        className="show-on-comment-hover"
        onCommentSaved={onReplySave}
        parentThingId={props.comment.name}
        aria-label="Reply"
      />
    </HStack>
  )
}

export default Header
