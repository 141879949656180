import React, { useState } from 'react'
import {
  Text,
  Center,
  Button,
  VStack,
  Spinner,
  Image,
  IconButton,
  Box,
  IconButtonProps,
  IconProps,
} from '@chakra-ui/react'
import { LazyImage } from 'react-lazy-images'
import { createPortal } from 'react-dom'
import { ArrowBackIcon, ArrowForwardIcon, CloseIcon } from '@chakra-ui/icons'

export interface GalleryImage {
  text?: string
  imageUrl?: string
  videoUrl?: string
}

interface FullscreenGalleryProps {
  image: GalleryImage
  showNextButton: boolean
  showPreviousButton: boolean
  onDismiss: () => void
  onNext: () => void
  onPrevious: () => void
}

const FullscreenGallery: React.FC<FullscreenGalleryProps> = (props) => {
  if (props.image.imageUrl) {
    const portalRoot = document.getElementById('portal-root')
    if (!portalRoot) {
      throw new Error('cannot find portal root')
    }

    const spacing = 5

    const buttonProps: IconButtonProps = {
      'aria-label': 'nav',
      position: 'absolute',
      zIndex: '902',
      backgroundColor: 'rgba(0,0,0,0.8)',
      _hover: { backgroundColor: 'rgb(0,0,0)' },
    }

    const iconProps: IconProps = {
      color: 'white',
    }

    const content = (
      <Center position="absolute" zIndex="900" inset={0}>
        <Box
          position="absolute"
          inset={0}
          backgroundColor="rgba(0,0,0,0.7)"
          onClick={() => props.onDismiss()}
        />
        {props.showPreviousButton && (
          <IconButton
            {...buttonProps}
            left={spacing}
            aria-label="previous"
            icon={<ArrowBackIcon {...iconProps} />}
            onClick={() => props.onPrevious()}
          />
        )}

        <Image
          alt="image"
          src={props.image.imageUrl}
          position="relative"
          zIndex="901"
          maxWidth="100%"
          maxHeight="100%"
        />

        {props.showNextButton && (
          <IconButton
            {...buttonProps}
            right={spacing}
            aria-label="previous"
            icon={<ArrowForwardIcon {...iconProps} />}
            onClick={() => props.onNext()}
          />
        )}

        <IconButton
          {...buttonProps}
          right={spacing}
          top={spacing}
          onClick={() => props.onDismiss()}
          icon={<CloseIcon {...iconProps} />}
        />
      </Center>
    )
    return createPortal(content, portalRoot)
  }
  return null
}

interface GalleryProps {
  images: GalleryImage[]
}

const INITIAL_VISIBLE_IMAGE_COUNT = 5

const Gallery: React.FC<GalleryProps> = ({ images }) => {
  const [showAllImages, setShowAllImages] = useState(false)
  const [fullscreenIndex, setFullscreenIndex] = useState<number | undefined>(undefined)

  const fullscreenImage = fullscreenIndex !== undefined ? images[fullscreenIndex] : undefined

  const fullscreenNextImage = () => {
    if (fullscreenIndex! < images.length - 1) {
      setFullscreenIndex(fullscreenIndex! + 1)
    }
  }

  const fullscreenPreviousImage = () => {
    if (fullscreenIndex! > 0) {
      setFullscreenIndex(fullscreenIndex! - 1)
    }
  }

  const visibleImages = showAllImages ? images : images.slice(0, INITIAL_VISIBLE_IMAGE_COUNT - 1)

  return (
    <>
      <VStack alignItems="stretch">
        {visibleImages.map((image, index) => {
          return (
            <VStack
              key={image.imageUrl}
              width="auto"
              backgroundColor="gray.100"
              overflow="hidden"
              borderRadius="5px"
              marginY={4}
              spacing={0}
            >
              <Center
                cursor="pointer"
                onClick={() => setFullscreenIndex(index)}
                width="100%"
                backgroundColor="gray.900"
              >
                {image.imageUrl ? (
                  <LazyImage
                    alt={image.text}
                    src={image.imageUrl}
                    placeholder={({ ref }) => (
                      <Center ref={ref} height="500px">
                        <Spinner color="white" />
                      </Center>
                    )}
                    actual={({ imageProps }) => <Image {...imageProps} />}
                  />
                ) : (
                  <video src={image.videoUrl} autoPlay={false} controls={true} />
                )}
              </Center>
              {image.text && (
                <Text padding={4} fontSize="md">
                  {image.text}
                </Text>
              )}
            </VStack>
          )
        })}
        {!showAllImages && images.length > INITIAL_VISIBLE_IMAGE_COUNT && (
          <Center>
            <Button onClick={() => setShowAllImages(true)}>
              Show all images ({images.length})
            </Button>
          </Center>
        )}
      </VStack>

      {fullscreenImage && (
        <FullscreenGallery
          image={fullscreenImage}
          showPreviousButton={fullscreenIndex! > 0}
          showNextButton={fullscreenIndex! < images.length - 1}
          onDismiss={() => setFullscreenIndex(undefined)}
          onNext={() => fullscreenNextImage()}
          onPrevious={() => fullscreenPreviousImage()}
        />
      )}
    </>
  )
}

export default Gallery
