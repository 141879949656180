import React, { useState } from 'react'
import { Circle, Flex, Text, Link, IconButton, Spacer } from '@chakra-ui/react'
import { Sub } from '../../state/subs'
import { Link as RouterLink } from 'react-router-dom'
import { ChevronDownIcon } from '@chakra-ui/icons'
import SubActions from './SubActions'

interface SubItemProps {
  sub: Sub
  isCurrent: boolean
}

const SubItem: React.FC<SubItemProps> = ({ sub, isCurrent }) => {
  const [showMore, setShowMore] = useState(false)

  const handleShowMore = (event: React.MouseEvent) => {
    event.preventDefault()
    setShowMore(!showMore)
  }
  return (
    <>
      <Link
        as={RouterLink}
        paddingY={2}
        paddingLeft={4}
        paddingRight={2}
        _hover={{ backgroundColor: 'blue.100' }}
        to={`/r/${sub.display_name}`}
      >
        <Flex>
          <Circle backgroundColor="gray.100" w="28px" h="28px" overflow="hidden" marginRight={2}>
            {sub.icon_img && <img className="icon" src={sub.icon_img} alt={sub.display_name} />}
          </Circle>
          <Text
            fontWeight={isCurrent ? 'bold' : 'normal'}
            isTruncated
            whiteSpace="nowrap"
            overflow="hidden"
          >
            r/{sub.display_name}
          </Text>
          <Spacer />
          <IconButton
            variant="link"
            onClick={handleShowMore}
            aria-label="more"
            icon={<ChevronDownIcon />}
          />
        </Flex>
        {showMore && <SubActions sub={sub.display_name} />}
      </Link>
    </>
  )
}

export default SubItem
