import React, { useEffect, useState } from 'react'
import { Post } from '../../state/posts'
import Spinner from '../common/Spinner'
import Gallery, { GalleryImage } from '../common/Gallery'

interface ImgurProps {
  post: Post
}

interface ImgurRequest {
  type: 'album' | 'gallery' | 'image'
  hash: string
}

interface ImgurImage {
  width: number
  height: number
  link: string
  description: string
  title: string
  type: string
}

const parseImgurUrl = (url: string): ImgurRequest | null => {
  const components = url.split('/')
  const hash = components[components.length - 1]
  if (hash.length < 4) {
    throw new Error('imgur url parse error')
  }
  if (url.includes('//imgur.com/a/')) {
    return {
      type: 'album',
      hash,
    }
  } else if (url.includes('//imgur.com/gallery/')) {
    return {
      type: 'gallery',
      hash,
    }
  } else if (url.includes('imgur.com/')) {
    return {
      type: 'image',
      hash,
    }
  }
  return null
}

const imgurRequest = async (path: string) => {
  const headers = new Headers()
  headers.append('Authorization', 'Client-ID ' + process.env.REACT_APP_IMGUR_CLIENT_ID)
  const url = `https://api.imgur.com/3/${path}`
  const resp = await fetch(url, {
    method: 'get',
    headers,
    referrerPolicy: 'no-referrer',
  })
  const data = await resp.json()
  if (data.status === 200) {
    return data.data
  }
  throw new Error('Cannot load images from Imgur')
}

const getAlbumImages = async (hash: string): Promise<ImgurImage[]> => {
  return imgurRequest(`album/${hash}/images`)
}

const getSingleImage = async (hash: string): Promise<ImgurImage> => {
  return imgurRequest(`image/${hash}`)
}

const getImages = async (url: string): Promise<ImgurImage[]> => {
  const imgurReq = parseImgurUrl(url)
  if (!imgurReq) {
    return []
  }
  if (imgurReq.type === 'image') {
    const image = await getSingleImage(imgurReq.hash)
    return [image]
  } else {
    return await getAlbumImages(imgurReq.hash)
  }
}

const ImgurAlbum: React.FC<ImgurProps> = ({ post }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [images, setImages] = useState<ImgurImage[]>([])

  useEffect(() => {
    setIsLoading(true)
    getImages(post.url).then((images) => setImages(images))
    setIsLoading(false)
  }, [post, setIsLoading])

  const imagesForGallery = images.map(
    (image): GalleryImage => {
      return {
        text: image.description,
        videoUrl: image.type === 'video/mp4' ? image.link : undefined,
        imageUrl: image.type !== 'video/mp4' ? image.link : undefined,
      }
    }
  )

  if (isLoading) {
    return <Spinner />
  }

  return <Gallery images={imagesForGallery} />
}

const Imgur: React.FC<ImgurProps> = ({ post }) => {
  if (post.url.endsWith('.gifv')) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={post.url}>
        {post.url}
      </a>
    )
  } else if (post.url.includes('//imgur.com/')) {
    return <ImgurAlbum post={post} />
  } else if (post.url.includes('//i.imgur.com')) {
    const image: GalleryImage = {
      imageUrl: post.url,
    }
    return <Gallery images={[image]} />
  }
  return null
}

export default Imgur
