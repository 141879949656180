import React from 'react'
import { useContent } from '../../state/content'
import Comment from '../comments/Comment'
import Video from './Video'
import Images from './Images'
import Spinner from '../common/Spinner'
import { Box, Center, CloseButton, Flex, Heading, useColorModeValue } from '@chakra-ui/react'
import UserContent from './UserContent'
import Toolbar from './Toolbar'
import Twitch from './Twitch'
import Imgur from './Imgur'
import Youtube from './Youtube'
import { useHistory } from 'react-router-dom'

interface CommentsProps {
  sub: string
  postId: string
}

const Content: React.FC<CommentsProps> = (props: CommentsProps) => {
  const { contentIsLoading, commentsAreLoading, postContent, comments, appendComment } = useContent(
    props.sub,
    props.postId
  )

  const history = useHistory()

  const closeContent = () => {
    history.push('/')
  }

  const textContentBackground = useColorModeValue('gray.50', 'gray.900')

  if (contentIsLoading) {
    return (
      <Center height="100%">
        <Spinner />
      </Center>
    )
  }

  if (!postContent) {
    return <Center height="100%">No post</Center>
  }

  return (
    <Box padding="6" maxWidth="64rem" marginX="auto" position="relative">
      <CloseButton
        display={{ base: 'block', lg: 'none' }}
        zIndex="999"
        size="lg"
        onClick={() => closeContent()}
        position="fixed"
        right={4}
        top={4}
      />

      <Box className="post-overview">
        <Flex className="header">
          <Heading as="h2" flex="1" marginBottom="4" className="post-title">
            {postContent.title}
          </Heading>
        </Flex>

        <UserContent
          content={postContent.selftext_html}
          padding={6}
          borderRadius="10px"
          backgroundColor={textContentBackground}
          className="content-body"
          fontSize="md"
        />

        <Images post={postContent} />

        <Twitch post={postContent} />

        <Imgur post={postContent} />

        <Video post={postContent} />

        <Youtube post={postContent} />
      </Box>

      <Toolbar marginY={4} post={postContent} onNewComment={(comment) => appendComment(comment)} />

      {commentsAreLoading ? (
        <Spinner marginTop={6} />
      ) : (
        <Box className="comments">
          {comments.map((comment) => (
            <Comment
              postAuthor={postContent.author}
              postId={postContent.name}
              comment={comment}
              key={comment.id}
            />
          ))}
        </Box>
      )}
    </Box>
  )
}

export default Content
