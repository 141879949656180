import React from 'react'
import { FormControl, FormControlProps, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'
import { Field, FieldProps } from 'formik'

interface ChakraFormikFieldProps extends FormControlProps {
  validate?: (value: string) => string | undefined
  fieldKey: string
  label: string
  children?: (props: FieldProps) => void
}

const ChakraFormikField: React.FC<ChakraFormikFieldProps> = (props) => {
  const { validate, fieldKey, label, ...rest } = props

  return (
    <Field name={fieldKey} validate={validate}>
      {(fieldProps: FieldProps) => {
        return (
          <FormControl
            {...rest}
            isInvalid={(fieldProps.form.errors[fieldKey] && fieldProps.form.touched[fieldKey]) as boolean}
          >
            <FormLabel htmlFor={fieldProps.field.name}>{label}</FormLabel>
            {props.children ? (
              props.children(fieldProps)
            ) : (
              <Input {...fieldProps.field} id={fieldProps.field.name} value={fieldProps.field.value} />
            )}
            <FormErrorMessage>{fieldProps.form.errors[fieldKey]}</FormErrorMessage>
          </FormControl>
        )
      }}
    </Field>
  )
}

export default ChakraFormikField
