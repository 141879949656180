import React from 'react'
import { Button, Stack, useToast } from '@chakra-ui/react'
import { useSubs } from '../../state/subs'
import Spinner from '../common/Spinner'
import SidebarOverlay from './SidebarOverlay'

interface SubActionsProps {
  sub: string
}

const SubActions: React.FC<SubActionsProps> = ({ sub }) => {
  const { isSubscribed, isLoading, subscribe, unsubscribe, isFavorite, makeFavorite } = useSubs(
    false
  )
  const toast = useToast()

  const handleSubscribe = async (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    await subscribe(sub)
    toast({
      title: `Subscribed to r/${sub}`,
    })
  }

  const handleUnsubscribe = async (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    await unsubscribe(sub)
    toast({
      title: `Unsubscribed from r/${sub}`,
    })
  }

  const toggleFavourite = async (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    const newFavState = !isFavorite(sub)
    makeFavorite(sub, newFavState)
    if (newFavState) {
      toast({
        title: `Added r/${sub} to favorites`,
      })
    } else {
      toast({
        title: `Removed r/${sub} from favorites`,
      })
    }
  }

  if (isLoading) {
    return (
      <div className="actions-dropdown" style={{ backgroundColor: 'white' }}>
        <Spinner />
      </div>
    )
  } else {
    return (
      <Stack spacing={2} padding={2}>
        {isSubscribed(sub) ? (
          <Button key="unsubscribe" onClick={handleUnsubscribe}>
            Unsubscribe
          </Button>
        ) : (
          <Button key="subscribe" onClick={handleSubscribe}>
            Subscribe
          </Button>
        )}

        <Button onClick={toggleFavourite}>
          {isFavorite(sub) ? 'Remove from favorites' : 'Mark as favorite'}
        </Button>

        <SidebarOverlay sub={sub} />
      </Stack>
    )
  }
}

export default SubActions
