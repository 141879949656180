import React, { useState } from 'react'
import { LikeStatus, useVote, VoteType } from '../../state/vote'
import { IconButton, IconButtonProps, Stack, StackProps, useColorModeValue } from '@chakra-ui/react'
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons'

interface VoteButtonProps extends IconButtonProps {
  direction: number
  hasVoted: boolean
  isVoting: boolean
}

const VoteButton: React.FC<VoteButtonProps> = (props) => {
  const icon = props.direction === 1 ? <ArrowUpIcon /> : <ArrowDownIcon />
  const voteColor = props.direction === 1 ? 'upvote.900' : 'downvote.900'
  const defaultBg = useColorModeValue('blue.50', 'gray.900')
  const defaultFg = useColorModeValue('gray.600', 'gray.400')
  const bgColor = props.hasVoted ? voteColor : defaultBg
  const fgColor = props.hasVoted ? 'white' : defaultFg

  const { hasVoted, isVoting, direction, ...rest } = props

  return (
    <IconButton
      {...rest}
      size="xs"
      backgroundColor={bgColor}
      color={fgColor}
      isRound
      isLoading={props.isVoting}
      icon={icon}
      aria-label="Upvote"
      _hover={{
        color: 'white',
        backgroundColor: voteColor,
      }}
    />
  )
}

interface VoteProps extends StackProps {
  thingId: string
  likes: boolean | null
  onVote?: (vote: VoteType) => void
}

const Vote: React.FC<VoteProps> = (props) => {
  const { thingId, likes, onVote, ...rest } = props
  const { upvote, downvote, isUpvoting, isDownvoting, unvote } = useVote()

  const isUpvoted = likes === true
  const isDownvoted = likes === false

  const handleUpvote = async (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()

    if (isUpvoted) {
      await unvote(thingId)
    } else {
      await upvote(thingId)
    }
    if (onVote) onVote(1)
  }

  const handleDownvote = async (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()

    if (isDownvoted) {
      await unvote(thingId)
    } else {
      await downvote(thingId)
    }
    if (onVote) onVote(-1)
  }

  return (
    <Stack {...rest}>
      <VoteButton
        aria-label="Downvote"
        direction={1}
        hasVoted={isUpvoted}
        isVoting={isUpvoting}
        onClick={handleUpvote}
      />
      <VoteButton
        aria-label="Downvote"
        direction={-1}
        hasVoted={isDownvoted}
        isVoting={isDownvoting}
        onClick={handleDownvote}
      />
    </Stack>
  )
}

export const PostVote: React.FC<VoteProps> = (props) => {
  return <Vote {...props} />
}

export const CommentVote: React.FC<VoteProps> = (props) => {
  const [voteStatus, setVoteStatus] = useState<LikeStatus>(props.likes)

  return (
    <Vote
      {...props}
      likes={voteStatus}
      onVote={(vote) => {
        if (vote === 1) {
          setVoteStatus(true)
        } else if (vote === -1) {
          setVoteStatus(false)
        } else {
          setVoteStatus(null)
        }
      }}
    />
  )
}
