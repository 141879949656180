import React from 'react'
import { useSubmit, NewPost } from '../../state/submit-post'
import { Button, HStack, Spacer, VStack, Text, Textarea, useToast } from '@chakra-ui/react'
import Spinner from '../common/Spinner'
import { Field, FieldProps, Form, Formik } from 'formik'
import { validateUrl, validateLength } from '../../utils/validation'
import ChakraFormikField from './ChakraFormikField'

interface PostFormProps {
  sub: string
  onClose: () => void
  onSubmit: (newPost: NewPost) => void
}

const PostForm: React.FC<PostFormProps> = (props) => {
  const { isLoading, submit } = useSubmit()

  const onClose = props.onClose

  const toast = useToast()

  const initialValues: NewPost = {
    url: '',
    title: '',
    text: '',
    kind: 'self',
    sr: props.sub,
  }

  if (isLoading) {
    return <Spinner />
  } else {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          try {
            await submit(values)
            actions.setSubmitting(false)
            toast({
              title: 'Post submitted',
              description: `Submitted to ${values.sr}`,
              status: 'success',
              duration: 4000,
              isClosable: true,
            })
            props.onSubmit(values)
          } catch (error) {
            toast({
              title: 'Submission failed',
              description: (error as Error).message,
              status: 'error',
              duration: 4000,
              isClosable: true,
            })
          }
        }}
      >
        {(props) => (
          <Form>
            <VStack alignItems="stretch" spacing={4}>
              <HStack spacing={4}>
                <HStack as="label">
                  <Field type="radio" name="kind" value="link" />
                  <Text marginLeft={2}>Link</Text>
                </HStack>
                <HStack as="label">
                  <Field type="radio" name="kind" value="self" />
                  <Text marginLeft={2}>Text</Text>
                </HStack>
              </HStack>

              {props.values.kind === 'link' && (
                <ChakraFormikField validate={validateUrl} fieldKey="url" label="URL" />
              )}

              <ChakraFormikField validate={validateLength(3)} fieldKey="title" label="Title" />

              {props.values.kind === 'self' && (
                <ChakraFormikField validate={validateLength(1)} fieldKey="text" label="Content">
                  {({ field }: FieldProps) => {
                    return <Textarea {...field} id={field.name} value={field.value} />
                  }}
                </ChakraFormikField>
              )}

              <HStack spacing={2} marginBottom={4}>
                <Spacer />
                <Button onClick={() => onClose()} variant="ghost">
                  Cancel
                </Button>
                <Button type="submit" colorScheme="blue">
                  Submit
                </Button>
              </HStack>
            </VStack>
          </Form>
        )}
      </Formik>
    )
  }
}

export default PostForm
