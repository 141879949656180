import React, { useState } from 'react'
import { Comment } from '../../state/content'
import { fetchChildComments } from '../../state/content'
import CommentComp from './Comment'
import ExpandButton from './ExpandButton'

interface MoreCommentsProps {
  commentPlaceholder: Comment
  postId: string
  postAuthor: string
}

const MoreComments: React.FC<MoreCommentsProps> = (props) => {
  const [moreComments, setMoreComments] = useState<Comment[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const loadMoreComments = async () => {
    setIsLoading(true)
    const resp = await fetchChildComments(props.postId, props.commentPlaceholder.children)
    setMoreComments(resp)
    setIsLoading(false)
  }

  if (moreComments.length) {
    return (
      <div>
        {moreComments.map((childComment) => {
          return (
            <CommentComp
              postAuthor={props.postAuthor}
              postId={props.postId}
              comment={childComment}
              key={childComment.id}
            />
          )
        })}
      </div>
    )
  } else {
    return (
      <div className="more-comments">
        <ExpandButton isLoading={isLoading} onClick={() => loadMoreComments()}>
          {props.commentPlaceholder.count} more comments
        </ExpandButton>
      </div>
    )
  }
}

export default MoreComments
