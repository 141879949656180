import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { createToken } from '../utils/auth'
import { paramsFromQueryString } from '../utils/request'
import Spinner from './common/Spinner'

const RedditCallback: React.FC = () => {
  const history = useHistory()
  const { search } = useLocation()
  const params = paramsFromQueryString(search.replace('?', ''))

  useEffect(() => {
    createToken(params.code as string).then((success) => {
      if (success) {
        history.push('/')
      }
    })
  }, [history, params.code])

  return (
    <div>
      <Spinner />
      {/* <pre>callback from reddit : {JSON.stringify(params)}</pre> */}
    </div>
  )
}

export default RedditCallback
